const Joi =require('joi');

// require and configure dotenv, will load vars in .env in PROCESS.ENV
// all variables for react must start with REACT_APP_
// require('dotenv').config();

// define validation for all the env vars for REACT_APP_
const envReactVarsSchema = Joi.object({
  REACT_APP_API_BASE_URL: Joi.string()
    .default('http://localhost:9096'),
  REACT_APP_API_PATH: Joi.string()
    .default(''),
  REACT_APP_FF_ENABLE_API_SANDBOX: Joi.string().default('enable_api_sandbox'),
  REACT_APP_FF_ENABLE_NEWS_MANAGEMENT: Joi.string().default('enable_news_management'),
  REACT_APP_FF_ENABLE_RATECARD_MANAGEMENT: Joi.string().default('enable_rate_card_mgmt_for_tpm'),
  REACT_APP_FF_ENABLE_PARTNER_PAGE: Joi.string().default('enable_partner_page'),
  REACT_APP_FF_ENABLE_APIS_TAB_FOR_TPU: Joi.string().default('enable_apis_tab_for_tpu'),
  REACT_APP_FF_ENABLE_API_PROVISIONING_API_LIST_VIEW: Joi.string().default('enable_api_provisioning_api_list_view'),
  REACT_APP_FF_ENABLE_USERS_TAB_FOR_TPU: Joi.string().default('enable_users_tab_for_tpu'),
  REACT_APP_FF_ENABLE_API_PROVISIONING_TAB_FOR_TPM: Joi.string().default('enable_api_provisioning_tab_for_tpm'),
  REACT_APP_FF_ENABLE_USERS_TAB_FOR_TPM: Joi.string().default('enable_users_tab_for_tpm'),
  REACT_APP_FF_ENABLE_GATEWAY_STATUS_COLUMN_FOR_PARTNER_LIST: Joi.string().default('enable_gateway_status_column_for_partner_list'),
  REACT_APP_FF_ENABLE_ASSET_ID_VALIDATION: Joi.string().default('enable_asset_id_validation'),
  REACT_APP_TRUSTED_PARTNER_ADMIN: Joi.string().default('trusted_partner_admin'),
  REACT_APP_TRUSTED_PARTNER_MEMBER: Joi.string().default('trusted_partner_member'),
  REACT_APP_TRUSTED_PARTNER_VIEWER: Joi.string().default('trusted_partner_viewer'),
  REACT_APP_FF_ENABLE_GRAPHQL_DOC_PAGE: Joi.string().default('enable_graphql_doc_page'),
  REACT_APP_FF_ENABLE_WHOLESALE_API_SANDBOX: Joi.string().default('enable_wholesale_api_sandbox')
}).unknown()
  .required();

const {
  error,
  value: envVars,
} = envReactVarsSchema.validate(process.env);

if (error) {
  throw new Error(`Config validation error: ${error&&error.message}`);
}

const reactConfig = {
  apiPath: envVars.REACT_APP_API_PATH,
  baseUrl: envVars.REACT_APP_API_BASE_URL,
  oktaVars: {
    issuer: undefined,
    clientId: undefined,
    redirectUri: undefined,
    pkce: true,
  },
  aad_vars: {
    AAD_AUTHORITY: undefined,
    AAD_CLIENT_ID: undefined,
    AAD_TENANT_ID: undefined,
  },
  featureFlags: {
    FF_ENABLE_API_SANDBOX: envVars.REACT_APP_FF_ENABLE_API_SANDBOX,
    FF_ENABLE_NEWS_MANAGEMENT: envVars.REACT_APP_FF_ENABLE_NEWS_MANAGEMENT,
    FF_ENABLE_RATE_CARD_MGMT_FOR_TPM: envVars.REACT_APP_FF_ENABLE_RATECARD_MANAGEMENT,
    FF_ENABLE_PARTNER_PAGE: envVars.REACT_APP_FF_ENABLE_PARTNER_PAGE,
    FF_ENABLE_APIS_TAB_FOR_TPU: envVars.REACT_APP_FF_ENABLE_APIS_TAB_FOR_TPU,
    FF_ENABLE_API_PROVISIONING_API_LIST_VIEW: envVars.REACT_APP_FF_ENABLE_API_PROVISIONING_API_LIST_VIEW,
    FF_ENABLE_USERS_TAB_FOR_TPU: envVars.REACT_APP_FF_ENABLE_USERS_TAB_FOR_TPU,
    FF_ENABLE_API_PROVISIONING_TAB_FOR_TPM: envVars.REACT_APP_FF_ENABLE_API_PROVISIONING_TAB_FOR_TPM,
    FF_ENABLE_USERS_TAB_FOR_TPM: envVars.REACT_APP_FF_ENABLE_USERS_TAB_FOR_TPM,
    FF_ENABLE_GATEWAY_STATUS_COLUMN_FOR_PARTNER_LIST: envVars.REACT_APP_FF_ENABLE_GATEWAY_STATUS_COLUMN_FOR_PARTNER_LIST,
    FF_ENABLE_ASSET_ID_VALIDATION: envVars.REACT_APP_FF_ENABLE_ASSET_ID_VALIDATION,
    FF_ENABLE_GRAPHQL_DOC_PAGE: envVars.REACT_APP_FF_ENABLE_GRAPHQL_DOC_PAGE,
    FF_ENABLE_WHOLESALE_API_SANDBOX: envVars.REACT_APP_FF_ENABLE_WHOLESALE_API_SANDBOX
  },
  enterpriseRole: {
    TRUSTED_PARTNER_ADMIN: envVars.REACT_APP_TRUSTED_PARTNER_ADMIN,
    TRUSTED_PARTNER_MEMBER: envVars.REACT_APP_TRUSTED_PARTNER_MEMBER,
    TRUSTED_PARTNER_VIEWER: envVars.REACT_APP_TRUSTED_PARTNER_VIEWER
  }
};

module.exports = reactConfig;
