/* eslint-disable max-len */
import React, {useEffect, useState} from 'react';
import Routes from './config/route';
import {connect} from 'react-redux';
import {getUser, autoOnBoardUser, updateUserLogin} from './api/services/users';
import {getAPIs} from './api/services/config';
import {createLogAPI} from './api/services/log';
import {SignOut} from './components/signIn/auth';
import {UserConfig} from './actions/userConfigActon';
import {LoaderConfig} from './actions/loaderConfig';
import {FeatureFlag} from './actions/featureFlagAction';
import {
  UnauthenticatedTemplate,
  MsalAuthenticationTemplate,
  useMsal,
  useIsAuthenticated
} from '@azure/msal-react';
import {Route, Switch, useHistory, useLocation} from 'react-router-dom';
import NotFound from './components/NotFound';
import Alerts from './components/utils/userAlert';
import Cookies from 'universal-cookie';
import {getAllFeatureFlagValues} from './api/services/getFeatureFlagValue';
import {featureFlags} from './config/reactConfig';
import {InteractionType} from '@azure/msal-browser';
import {InteractionRequiredAuthError} from '@azure/msal-common';
import {loginRequest} from './authConfig';
const {ErrorAlert, CustomErrorAlert} = Alerts;

const LoadFeatureFlagToRedux = props => {
  const history = useHistory();
  const isAuthenticated = useIsAuthenticated();
  const {changeFeatureFlag, userConfig, globalConfig} = props;
  useEffect(() => {
    const asyncFn = async () => {
      if (isAuthenticated) {
        const featureFlagValues = {};
        getAllFeatureFlagValues(localStorage.getItem('idToken'))
          .then(response => {
            if (response?.data) {
              Object.keys(featureFlags).forEach(key => {
                featureFlagValues[featureFlags[key]] = response.data[featureFlags[key]];
              });
              changeFeatureFlag(featureFlagValues);
              if (!featureFlagValues[featureFlags.FF_ENABLE_PARTNER_PAGE] &&
                globalConfig?.ALL_PARTNER_PAGES?.split(',')?.includes(window.location.pathname?.split('/')[1])) {
                CustomErrorAlert('Page not available, redirecting to home page.');
                history.push('/');
              }
              if (!featureFlagValues[featureFlags.FF_ENABLE_NEWS_MANAGEMENT] &&
                globalConfig?.ALL_NEWS_PAGES?.split(',')?.includes(window.location.pathname?.split('/')[1])) {
                CustomErrorAlert('Page not available, redirecting to home page.');
                history.push('/');
              }
              if (!featureFlagValues[featureFlags.FF_ENABLE_RATE_CARD_MGMT_FOR_TPM] &&
                globalConfig?.ALL_RATECARD_PAGES?.split(',')?.includes(window.location.pathname?.split('/')[1])) {
                CustomErrorAlert('Page not available, redirecting to home page.');
                history.push('/');
              }
            }
          });
      }
    };
    asyncFn();
  }, [isAuthenticated]);
  useEffect(() => {
    if (isAuthenticated && userConfig && globalConfig) {
      if (!userConfig?.role?.includes('admin') && 
        !userConfig?.buName?.find(bu=> globalConfig.PARTNERS_BU_NAMES.split(',').find((pBUName) => pBUName === bu.name)) &&
        globalConfig?.ALL_PARTNER_PAGES?.split(',')?.includes(window.location.pathname?.split('/')[1])) {
        CustomErrorAlert('Page not available, redirecting to home page.');
        history.push('/');
      }
    }
  }, [isAuthenticated, userConfig, globalConfig]);
  return null;
};

const AppWithRouterAccess = props => {
  const {userConfig, globalConfig, changeUserConfig, loaderConfig, changeLoaderConfig, changeFeatureFlag, featureFlag} = props;
  const [localStorageUpdated, setLocalStorageUpdated] = useState(false);
  const [routeUpdated, setRouteUpdated] = useState();
  const cookies = new Cookies();
  const history = useHistory();

  const {instance, accounts} = useMsal();
  const isAuthenticated = useIsAuthenticated();

  let envVar;
  if (window.location.origin.includes('-dev')) {
    envVar = 'dev';
  } else if (window.location.origin.includes('-stg')) {
    envVar = 'stg';
  } else {
    envVar = 'prd';
  }

  const setUser = (user, role, id, my_partners = false) => {
    localStorage.setItem('id', JSON.stringify({...user, role: role, id: id, my_partners: my_partners}));
    changeUserConfig({...user, role: role, id: id});
    if (localStorage.getItem('token-storage')) {
      const token = JSON.parse(localStorage.getItem('token-storage'))?.idToken;
      cookies.set('OAUTH_TOKEN', token, {domain: '.t-mobile.com', path: '/'});
      cookies.set('DEVPORTALV3_ENV', envVar, {domain: '.t-mobile.com', path: '/'});
    }

    changeLoaderConfig(false);
    createLogAPI({classification: 'login'});
  };

  const handleUserUpdate = React.useCallback(() => {
    changeLoaderConfig(true);
    const token = localStorage.getItem('token-storage');
    const {claims} = JSON.parse(token);
    if (token && token !== '{}') {
      if (Object.keys(userConfig).length === 0) {
        const email = claims.email;
        const user = {
          name: claims.name,
          user_id: email,
          test: false
        };
        let role;
        getUser(email, {login: true}).then(async (res) => {
          const userResponse = await res.data.data;
          role = userResponse.role;
          if (!userResponse.blocked) {
            user['name'] = userResponse.name;
            user['buName'] = userResponse?.buName;
            user['userGroup'] = userResponse?.userGroup;
            user['enterprise'] = userResponse?.enterprise;
            getAPIs('my_partners').then(async (res) => {
              const response = await res.data.data;
              if (response.length > 0) {
                setUser(user, role, userResponse._id, true);
              } else {
                setUser(user, role, userResponse._id);
              }
            }).catch(err => {
              setUser(user, role, userResponse._id);
            });
            updateUserLogin().then(async (res) => {
            }).catch(err => {
              console.log('unable to set last login');
            });
          } else {
            CustomErrorAlert('User blocked! Please Contact Admin');
            setTimeout(() => {
              SignOut(instance);
            }, 2000);
          }
          changeUserConfig(user);
        }).catch(async (err) => {
          const res = err.response && err.response.data;
          if (res && res?.message?.includes('User does not exists') && res.statusCode === 404) {
            const user_payload = {
              name: claims.name,
              user_id: email,
            };
            if (claims.groups && claims.groups.length > 0) {
              claims.groups?.every(group=> {
                const splitInfo = group.split(':');
                // extract and push buName from group for only Wholesate to auto onboard the user
                if (splitInfo.length > 4 && splitInfo[4].trim() === 'Wholesale') {
                  user_payload.buName = 'Wholesale';
                  return false;
                }
                return true;
              });
            }
            try {
              const autoOnBoardUserResponse = await autoOnBoardUser(user_payload);
              const {name = '', buName = [], userGroup = [], user_id = '', _id = '', role = [], test = false, enterprise = []} = autoOnBoardUserResponse?.data?.data ?? {};
              setUser({name, buName, userGroup, user_id, test}, role, _id, enterprise);
            } catch (err) {
              console.log(err);
              ErrorAlert(err);
              setTimeout(() => {
                SignOut(instance);
              }, 2000);
            }
          } else {
            console.log('Error during user validation:', res);
            setTimeout(() => {
              SignOut(instance);
            }, 3000);
          }
        });
      }
    }
  }, []);

  useEffect(() => {
    changeLoaderConfig(false);
    if (isAuthenticated) {
      const callTokenAPI = async () => {
        const clientId = instance.getConfiguration().auth.clientId;
        const accessTokenRequest = {
          scopes: [`${clientId}/.default`],
          account: accounts[0]
        };
        let tokenResponse;
        try {
          await instance.initialize();
          tokenResponse = await instance?.acquireTokenSilent(accessTokenRequest);
          const currentTime = Math.floor(Date.now() / 1000);
          const timeUntilExpiry = tokenResponse.idTokenClaims.exp - currentTime;
          const tokenRenewalOffsetSeconds = instance.getConfiguration().system.tokenRenewalOffsetSeconds;
          if (timeUntilExpiry <= tokenRenewalOffsetSeconds) {
            const forceRefresh = {...accessTokenRequest, forceRefresh: true};
            tokenResponse = await instance.acquireTokenRedirect(forceRefresh);
          }
        } catch (e) {
          if (!(e instanceof InteractionRequiredAuthError)) {
            throw e;
          }
          // Auth expired, and tokens can't be auto-refreshed.
          tokenResponse = await instance.acquireTokenRedirect(accessTokenRequest);      
        }
        
        const tokenStorage = {
          accessToken: tokenResponse?.accessToken,
          idToken: tokenResponse?.idToken,
          claims: tokenResponse?.idTokenClaims
        };
        localStorage.setItem('token-storage', JSON.stringify(tokenStorage));
        cookies.set('OAUTH_TOKEN', tokenStorage.idToken, {domain: '.t-mobile.com', path: '/'});
        cookies.set('DEVPORTALV3_ENV', envVar, {domain: '.t-mobile.com', path: '/'});
        setLocalStorageUpdated(true);
        if (!localStorage.getItem('id') && localStorage.getItem('token-storage')) {
          handleUserUpdate();
        }
      };
      callTokenAPI();
    }
  }, [isAuthenticated, routeUpdated]);

  const RouteWithSubRoutes = route => {
    const {path, exact, component, secure, routes} = route;
    // update for each route change and refresh the token for each route change.
    setRouteUpdated(path);
    const location = useLocation();
    const clientId = instance.getConfiguration().auth.clientId;
    const currentPath = location.pathname;
    const commonPageForAuthAndUnAuth = [
      '/',
      '/data-use-policy',
      '/terms-of-use'
    ];
    if (secure || (isAuthenticated && commonPageForAuthAndUnAuth.indexOf(path) !== -1)) {
      if (Object.keys(userConfig).length &&
        ((!Object.keys(userConfig).length > 0 &&
          currentPath.includes('documents') &&
          path === '/documents'
        ) ||
          (!Object.keys(userConfig).length > 0 &&
            currentPath.includes('sdk')
          ) || (!Object.keys(userConfig).length > 0 &&
            (currentPath.includes('api-reference') || (userConfig && userConfig.role && !userConfig.role.includes('admin') &&
              !userConfig.role.includes('trusted_partner_manager') && currentPath.includes('my_partners')))
        ))) {
        return (<Route><NotFound /></Route>);
      } else {
        return (
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={{
              scopes: loginRequest.scopes,
              account: accounts[0]
            }}
            key={path}
          >{localStorageUpdated &&
              <Route path={path}
                exact={exact}
                component={component}
              />
            }
          </MsalAuthenticationTemplate >);
      }
    } else {
      return (
        <UnauthenticatedTemplate key={path}>
          <Route
            path={path}
            exact={exact}
            component={component}
            routes={routes}
          //  Issuer={Issuer}
          />
        </UnauthenticatedTemplate>
      );
    }
  };

  return (
    <>
      <LoadFeatureFlagToRedux changeFeatureFlag={changeFeatureFlag} globalConfig={globalConfig} userConfig={userConfig} />
      <Switch>
        {/* private routes  */}
        {Routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))}
        <Route path="*"> <NotFound /></Route>
      </Switch>
    </>
  );
};

const mapStateToProps = ({userConfig, loaderConfig, featureFlag, globalConfig}) => ({userConfig, loaderConfig, featureFlag, globalConfig});
const mapDispatchToProps = dispatch => ({
  changeUserConfig: data => dispatch(UserConfig(data)),
  changeLoaderConfig: data => dispatch(LoaderConfig(data)),
  changeFeatureFlag: data => dispatch(FeatureFlag(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppWithRouterAccess);
